<template>
  <a-modal
    title="信息提示"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    :centered="true"
    @cancel="handleSelectCancel"
  >
    <!-- <p>请选择交办人</p> -->
    <a-select
      placeholder="请选择经办部门"
      style="width: 325px"
      @change="handleDepSelectChange"
    >
      <a-select-option
        v-for="item in departList"
        :key="item.id"
        :value="item.orderDeptName"
      >
        {{ item.orderDeptName }}
      </a-select-option>
    </a-select>
    <!-- <a-select
      placeholder="请选择交办人"
      style="width: 300px; margin-top: 20px"
      @change="handleSelectChange"
    >
      <a-select-option
        v-for="(item, index) in userList"
        show-search
        :key="index"
        :value="item.userId"
      >
        {{ item.realName }}
      </a-select-option>
    </a-select> -->
    <a-select
      class=""
      show-search
      placeholder="请选择交办人"
      style="width: 325px; margin-top: 20px"
      :options="userList"
      :getPopupContainer="
        (triggerNode) => {
          return triggerNode.parentNode;
        }
      "
      @change="handleSelectChange"
      @dropdownVisibleChange="dropdownVisibleChange"
      @search="setTop"
      @inputKeyDown="setTop"
    ></a-select>
    <a-input
      placeholder="请输入交办说明"
      style="width: 325px"
      class="mt23"
      type="textarea"
      auto-size
      :maxLength="100"
      v-show="isInput"
      v-model="operateDesc"
      @change="change"
    />
    <span class="ft-red block mt10" v-show="isNoUser">{{ errorTxt }}</span>
  </a-modal>
</template>
<script>
import { getUserList, getDepartList } from "@/utils/api";
import { Modal, Select, Input } from "ant-design-vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      default: false,
    },
    isNoUser: {
      type: Boolean,
      default: false,
    },
    isInput: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: "请选择交办人",
    },
  },
  components: {
    AModal: Modal,
    ASelect: Select,
    AInput: Input,
    ASelectOption: Select.Option,
  },
  data() {
    return {
      userList: [],
      departList: [],
      operateDesc: "",
    };
  },
  created() {
    this.getUserList();
    this.getDepartList();
  },
  methods: {
    setTop(value) {
      console.log(value);
      // this.dropdownVisibleChange();
      document.querySelector(".ant-select-dropdown").style.maxHeight = "150px";
      document.querySelector(".ant-select-dropdown").style.top = "110px";
      document.querySelector(".ant-select-dropdown-menu").style.maxHeight =
        "150px";
    },
    dropdownVisibleChange() {
      setTimeout(() => {
        document.querySelector(".ant-select-dropdown").style.top = "110px";
        document.querySelector(".ant-select-dropdown-menu").style.maxHeight =
          "150px";
        document.querySelector(".ant-select-dropdown").style.maxHeight =
          "150px";
      }, 1);
    },
    handleOk() {
      this.$emit("handleOk");
    },
    filterOption(input, option) {
      console.log(input, option.data);
      // return option.value.indexOf(input) >= 0;
    },
    change() {
      this.$emit("update:operateDesc", this.operateDesc);
    },
    handleSelectChange(value) {
      let chooseValue = "";
      this.defaultList.forEach((item) => {
        if (item.realName === value) {
          chooseValue = item.userId;
        }
      });
      this.$emit("handleSelectChange", chooseValue);
    },
    handleDepSelectChange(value) {
      this.$emit("handleDepSelectChange", value);
    },
    handleSelectCancel() {
      this.$emit("handleSelectCancel");
    },
    async getUserList() {
      const { data } = await getUserList();
      this.defaultList = data;
      data.forEach((item) => {
        this.userList.push({
          value: item.realName,
          label: item.realName,
        });
      });
      // this.userList = data;
    },
    async getDepartList() {
      const { data } = await getDepartList();
      this.departList = data;
      if (this.edit !== "true") {
        data.forEach((item) => {
          if (item.isDefault === 1) {
            this.orderBelongDept = item.orderDeptName;
          }
        });
      }
    },
  },
};
</script>