<template>
  <div :class="{ 'all-box': isLoading }">
    <div class="loading" v-show="isLoading">
      <a-spin>
        <a-icon
          slot="indicator"
          type="loading"
          style="font-size: 24px; color: #f39800"
          spin
        />
      </a-spin>
    </div>
    <Top :title="title" path="/home" :isEdit="id" />
    <div class="main-box pt44">
      <div class="report border-radius">
        <a-form-model
          layout="vertical"
          ref="reportForm"
          :model="form"
          :rules="rules"
        >
          <a-form-model-item ref="title" class="form-item" prop="title">
            <label class="iconfont icon-hps-apptext"><span>标题</span></label>
            <a-textarea
              v-model="form.title"
              placeholder="请输入标题"
              auto-size
              @change="titleInput"
              :maxLength="50"
              @blur="
                () => {
                  $refs.title.onFieldBlur();
                }
              "
            />
            <span class="right-tip">{{ titleLength }}/50</span>
          </a-form-model-item>
          <a-form-model-item
            class="form-item border-bottom pd form-item-flex"
            ref="orderCreateDept"
            prop="orderCreateDept"
          >
            <label class="iconfont icon-hps-appwodebumen"
              ><span>上报部门</span></label
            >
            <div>
              <select class="form-select" v-model="form.orderCreateDept">
                <option
                  v-for="item in departList"
                  :key="item.id"
                  :selected="item.isDefault === 1 ? 'selected' : ''"
                >
                  {{ item.orderDeptName }}
                </option>
              </select>
              <a-icon type="caret-down" />
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item class="form-item border-bottom pd">
          <label class="iconfont icon-hps-appwodebumen"
            ><span>经办部门</span></label
          >
          <select class="form-select" v-model="form.orderBelongDept">
            <option
              v-for="item in departList"
              :key="item.id"
              :selected="item.isDefault === 1 ? 'selected' : ''"
            >
              {{ item.orderDeptName }}
            </option>
          </select>
        </a-form-model-item> -->
          <a-form-model-item
            class="form-item border-bottom pd form-item-flex"
            ref="orderClassficationName"
            prop="orderClassficationName"
          >
            <label class="iconfont icon-hps-appgongdanfenlei"
              ><span>所属分类</span></label
            >
            <div class="type-select-box">
              <div style="text-align: right">
                <select
                  class="form-select"
                  v-model="form.orderClassficationName"
                >
                  <option
                    v-for="item in orderTypeList"
                    :key="item.id"
                    :selected="item.isDefault === 1 ? 'selected' : ''"
                  >
                    {{ item.orderClassficationName }}
                  </option>
                </select>
                <a-icon type="caret-down" />
              </div>
              <div style="text-align: right">
                <select
                  class="form-select mt30"
                  v-model="form.matterClassficationName"
                >
                  <option
                    v-for="item in problemTypeList"
                    :key="item.id"
                    :selected="item.isDefault === 1 ? 'selected' : ''"
                  >
                    {{ item.matterClassficationName }}
                  </option>
                </select>
                <a-icon type="caret-down" />
              </div>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item class="form-item border-bottom pd">
            <label class="iconfont icon-hps-appwentifenlei" style="color: #fff"
              ><span></span
            ></label>
            <select class="form-select" v-model="form.matterClassficationName">
              <option
                v-for="item in problemTypeList"
                :key="item.id"
                :selected="item.isDefault === 1 ? 'selected' : ''"
              >
                {{ item.matterClassficationName }}
              </option>
            </select>
          </a-form-model-item> -->
          <a-form-model-item
            class="form-item border-bottom pd form-item-flex"
            ref="matterPropertyName"
            prop="matterPropertyName"
          >
            <label class="iconfont icon-hps-appwentixingzhi"
              ><span>紧急性</span></label
            >
            <div>
              <select class="form-select" v-model="form.matterPropertyName">
                <option
                  v-for="item in natureTypeList"
                  :key="item.id"
                  :selected="item.isDefault === 1 ? 'selected' : ''"
                >
                  {{ item.matterPropertyName }}
                </option>
              </select>
              <a-icon type="caret-down" />
            </div>
          </a-form-model-item>
          <a-form-model-item
            class="form-item border-bottom pd form-item-flex"
            ref="areaLocation"
            prop="areaLocation"
          >
            <label class="iconfont icon-hps-appquyu"
              ><span>发生区域</span></label
            >
            <div>
              <select class="form-select" v-model="form.areaLocation">
                <option
                  v-for="item in areaList"
                  :key="item.id"
                  :selected="item.isDefault === 1 ? 'selected' : ''"
                >
                  {{ item.areaLocationName }}
                </option>
              </select>
              <a-icon type="caret-down" />
            </div>
          </a-form-model-item>
          <a-form-model-item ref="des" class="form-item" prop="des">
            <label class="iconfont icon-hps-appwentimiaoshu"
              ><span>事项描述</span></label
            >
            <template v-if="edit !== 'true'">
              <a-input
                v-model="form.des"
                type="textarea"
                @change="descInput"
                :auto-size="{ minRows: 2 }"
                :maxLength="200"
                @blur="
                  () => {
                    $refs.des.onFieldBlur();
                  }
                "
                placeholder="请描述您遇到的问题，不少于10个字"
              />
              <span class="right-tip">{{ descLength }}/200</span>
            </template>
            <template v-else>
              <p class="mt10">{{ form.des }}</p>
            </template>
          </a-form-model-item>
          <a-form-model-item ref="advice" class="form-item" prop="advice">
            <label class="iconfont icon-hps-appjianyijiqiwang"
              ><span>建议及期望</span></label
            >
            <template v-if="edit !== 'true'">
              <a-input
                v-model="form.advice"
                type="textarea"
                @change="expectInput"
                :auto-size="{ minRows: 2 }"
                :maxLength="200"
                @blur="
                  () => {
                    $refs.advice.onFieldBlur();
                  }
                "
                placeholder="请描述您目前期望的办法，不少于10个字"
              />
              <span class="right-tip">{{ expectLength }}/200</span>
            </template>
            <template v-else>
              <p class="mt10">{{ form.advice }}</p>
            </template>
          </a-form-model-item>
          <a-form-model-item
            class="form-item pd"
            v-if="!(edit && fileList.length == 0)"
          >
            <label class="iconfont icon-hps-apptupian"
              ><span>上传图片</span></label
            >
            <!-- <div class="upload-img">
            <a-button type="dashed" size="large"
              ><a-icon type="plus" class="icon-color"
            /></a-button>
          </div> -->
            <div class="upload-img">
              <a-upload
                :action="VUE_APP_API_UPLOAD_URL + '/file/uploadFile'"
                list-type="picture-card"
                :file-list="fileList"
                :before-upload="beforeUpload"
                @preview="handlePreview"
                @change="handleChange"
                accept="image/*"
                capture="camera"
                :headers="token"
                :remove="removeFile"
                :showUploadList="{
                  showRemoveIcon: noMove,
                }"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" class="icon-color" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="handle-btn border-top" v-if="edit == 'true'">
        <a-button class="save" @click="$router.go('-1')">取消</a-button>
        <a-button class="submit" @click="saveChangeHandle">保存</a-button>
      </div>
      <div class="handle-btn border-top" v-else-if="reSub == 'true'">
        <a-button class="save" @click="saveDraft">保存</a-button>
        <a-button class="submit" @click="submit">提交</a-button>
      </div>
      <div class="handle-btn border-top" v-else>
        <a-button class="save" @click="saveOrder">保存草稿</a-button>
        <a-button class="submit" @click="submit">提交</a-button>
      </div>
      <UserModal
        :visible="visible"
        :confirmLoading="confirmLoading"
        @handleOk="handleOk"
        @handleSelectCancel="handleSelectCancel"
        @handleSelectChange="handleSelectChange($event)"
        @handleDepSelectChange="handleDepSelectChange($event)"
        :isNoUser="isNoUser"
        :errorTxt="errorTxt"
      />
    </div>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

import {
  getOrderList,
  getNatureList,
  getProblemList,
  getAreList,
  getDepartList,
  saveOrder,
  submitOrder,
  getDetail,
  saveEditOrder,
  saveDraft,
} from "@/utils/api";
import {
  FormModel,
  Input,
  Button,
  Icon,
  Upload,
  Modal,
  Spin,
} from "ant-design-vue";
import UserModal from "@/components/modal/userModal";
import Top from "@/components/top/top";
export default {
  components: {
    AFormModel: FormModel,
    AFormModelItem: FormModel.Item,
    AButton: Button,
    ASpin: Spin,
    AInput: Input,
    ATextarea: Input.TextArea,
    AIcon: Icon,
    AUpload: Upload,
    AModal: Modal,
    UserModal,
    Top,
  },
  mounted() {
    this.getOrderList();
    this.getProblemList();
    this.getNatureList();
    this.getAreList();
    this.getDepartList();
    let token = localStorage.getItem("accessToken") || "";
    this.token = {
      Authorization: token,
    };
    if (this.id) {
      this.getDetail();
      this.title = "事项详情";
    }
    if (this.edit) {
      this.noMove = false;
    }
  },
  created() {},
  data() {
    const validatorTrim = (rule, value, callback) => {
      if (!value || !(value.trim() !== "")) {
        if (rule.field == "title") {
          callback(new Error("请输入标题"));
        } else if (rule.field == "des") {
          callback(new Error("请描述您遇到的问题，不少于10个字"));
        } else if (rule.field == "advice") {
          callback(new Error("请描述您目前期望的办法，不少于10个字"));
        }
      } else if (rule.field == "des" && value.length < 10) {
        callback(new Error("请描述您遇到的问题，不少于10个字"));
      } else if (rule.field == "advice" && value.length < 10) {
        callback(new Error("请描述您目前期望的办法，不少于10个字"));
      } else {
        callback();
      }
    };
    return {
      VUE_APP_API_UPLOAD_URL: process.env.VUE_APP_API_UPLOAD_URL,
      token: {},
      title: "我要上报",
      edit: this.$route.query.edit,
      reSub: this.$route.query.reSub, //重新提交保存草稿
      id: this.$route.query.id,
      orderTypeList: [],
      problemTypeList: [],
      natureTypeList: [],
      areaList: [],
      departList: [],
      descLength: 0,
      expectLength: 0,
      titleLength: 0,
      isNoUser: false,
      errorTxt: "请选择交办人",
      noMove: true,
      form: {
        title: "",
        orderClassficationName: "",
        matterClassficationName: "",
        matterPropertyName: "",
        orderCreateDept: "",
        des: "",
        advice: "",
        areaLocation: "",
        orderBelongDept: "",
        pictureName: "",
        pictureUrl: "",
        nextUserId: "",
      },
      rules: {
        title: [{ required: true, validator: validatorTrim, trigger: "blur" }],
        des: [
          {
            required: true,
            validator: validatorTrim,
            trigger: "blur",
          },
        ],
        advice: [
          {
            required: true,
            validator: validatorTrim,
            trigger: "blur",
          },
        ],
        orderCreateDept: [
          {
            required: true,
            message: "请选择上报部门",
            trigger: "change",
          },
        ],
        orderClassficationName: [
          {
            required: true,
            message: "请选择事项一级分类",
            trigger: "change",
          },
        ],
        matterClassficationName: [
          {
            required: true,
            message: "请选择事项二级分类",
            trigger: "change",
          },
        ],
        matterPropertyName: [
          {
            required: true,
            message: "请选择事项紧急性",
            trigger: "change",
          },
        ],
        areaLocation: [
          {
            required: true,
            message: "请选择发生区域",
            trigger: "change",
          },
        ],
      },
      previewVisible: false,
      previewImage: "",
      fileList: [],
      visible: false,
      confirmLoading: false,
      isLoading: false,
      uploading: false,
    };
  },
  watch: {},
  methods: {
    removeFile(file) {
      this.fileList = [];
      this.form.pictureUrl = "";
      this.form.pictureName = "";
      this.uploading = false;
    },
    //查询详情
    async getDetail() {
      const { data } = await getDetail(this.id);
      this.form = data;
      if (data.pictureUrl) {
        this.fileList.push({
          uid: "-1",
          name: data.pictureName,
          status: "done",
          url: data.pictureUrl,
        });
      }
    },
    //选择人员列表
    handleSelectChange(value) {
      this.form.nextUserId = value;
      this.isNoUser = false;
    },
    handleDepSelectChange(value) {
      this.form.orderBelongDept = value;
      this.isNoUser = false;
    },
    handleSelectCancel(e) {
      this.visible = false;
      this.confirmLoading = false;
    },
    handleOk() {
      if (!this.form.orderBelongDept) {
        this.isNoUser = true;
        this.errorTxt = "请选择部门";
        this.confirmLoading = false;
        return;
      }
      if (!this.form.nextUserId) {
        this.isNoUser = true;
        this.errorTxt = "请选择交办人";
        this.confirmLoading = false;
        return;
      }
      this.confirmLoading = true;
      this.submitOrder();
      // this.isLoading = true;
    },
    //确认提交
    async submitOrder() {
      if (this.uploading) {
        this.confirmLoading = false;
        this.$message.warning("图片尚未成功上传，请等待！");
        return;
      }
      const { resp_code, resp_msg, data } = await submitOrder(this.form);
      if (resp_code === 0) {
        this.confirmLoading = false;
        this.visible = false;
        this.$router.push(`/result?id=${data.id}`);
      } else {
        this.confirmLoading = false;
        this.isNoUser = true;
        this.errorTxt = resp_msg;
        this.isLoading = false;
      }
    },
    //提交工单
    submit() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          this.visible = true;
        } else {
          console.log(valid);
        }
      });
    },
    //保存草稿
    async saveOrderSub() {
      if (this.uploading) {
        this.confirmLoading = false;
        this.$message.warning("图片尚未成功上传，请等待！");
        return;
      }
      const { resp_code } = await saveOrder(this.form);
      if (resp_code === 0) {
        this.$message.success("保存成功", 2, () => {
          this.$router.push("/");
        });
      } else {
        this.isLoading = false;
      }
    },
    //重新提交保存
    async saveDraft() {
      if (this.uploading) {
        this.confirmLoading = false;
        this.$message.warning("图片尚未成功上传，请等待！");
        return;
      }
      const { resp_code } = await saveDraft(this.form);
      if (resp_code === 0) {
        this.$message.success("保存成功", 2, () => {
          this.$router.go("-1");
        });
      } else {
        this.isLoading = false;
      }
    },
    //保存工单
    saveOrder() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          if (this.id) {
            this.saveDraft();
          } else {
            this.saveOrderSub();
          }
          this.isLoading = true;
        } else {
          console.log(valid);
        }
      });
    },
    saveChangeHandle() {
      this.$refs.reportForm.validate((valid) => {
        if (valid) {
          this.saveEditOrder();
          this.isLoading = true;
        }
      });
    },
    //保存修改
    async saveEditOrder() {
      const { resp_code } = await saveEditOrder(this.form);
      if (resp_code === 0) {
        this.$message.success("保存成功", 2, () => {
          this.$router.push(`/detail?id=${this.id}`);
        });
      } else {
        this.isLoading = false;
      }
    },
    async getOrderList() {
      const { data } = await getOrderList();
      this.orderTypeList = data;
      if (this.edit !== "true") {
        this.form.orderClassficationName = data[0].orderClassficationName;
        data.forEach((item) => {
          if (item.isDefault === 1) {
            this.form.orderClassficationName = item.orderClassficationName;
          }
        });
      }
    },
    async getNatureList() {
      const { data } = await getNatureList();
      this.natureTypeList = data;
      if (this.edit !== "true") {
        this.form.matterPropertyName = data[0].matterPropertyName;
        data.forEach((item) => {
          if (item.isDefault === 1) {
            this.form.matterPropertyName = item.matterPropertyName;
          }
        });
      }
    },
    async getProblemList() {
      const { data } = await getProblemList();
      this.problemTypeList = data;
      if (this.edit !== "true") {
        this.form.matterClassficationName = data[0].matterClassficationName;
        data.forEach((item) => {
          if (item.isDefault === 1) {
            this.form.matterClassficationName = item.matterClassficationName;
          }
        });
      }
    },
    async getAreList() {
      const { data } = await getAreList();
      this.areaList = data;
      if (this.edit !== "true") {
        this.form.areaLocation = data[0].areaLocationName;
        data.forEach((item) => {
          if (item.isDefault === 1) {
            this.form.areaLocation = item.areaLocationName;
          }
        });
      }
    },
    async getDepartList() {
      const { data } = await getDepartList();
      this.departList = data;
      if (this.edit !== "true") {
        this.form.orderCreateDept = data[0].orderDeptName;
        data.forEach((item) => {
          if (item.isDefault === 1) {
            // this.form.orderBelongDept = item.orderDeptName;
            this.form.orderCreateDept = item.orderDeptName;
          }
        });
      }
    },
    //标题文字长度
    titleInput() {
      let length = this.form.title.length;
      console.log(length);
      if (length > 50) {
        this.titleLength = 50;
        return;
      }
      this.titleLength = length;
    },
    //描述文字长度
    descInput() {
      let length = this.form.des.length;
      if (length > 200) {
        this.descLength = 200;
        return;
      }
      this.descLength = length;
    },
    //预期文字长度
    expectInput() {
      let length = this.form.advice.length;
      if (length > 200) {
        this.expectLength = 200;
        return;
      }
      this.expectLength = length;
    },
    //隐藏上传图片
    handleCancel() {
      this.previewVisible = false;
    },
    //上传图片预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    beforeUpload(file) {
      // const isJpgOrPng =
      //   file.type === "image/jpeg" || file.type === "image/png";
      // if (!isJpgOrPng) {
      //   this.$message.error("You can only upload JPG file!");
      // }
      const isLt2M = file.size / 1024 / 1024 < 10;
      this.isLt2M = isLt2M;
      if (!isLt2M) {
        this.$message.error("请选择小于10M的图片!");
      }
      // return isJpgOrPng && isLt2M;
      return isLt2M;
    },
    //上传图片
    handleChange(info) {
      //info.file.response
      let response = info.file.response;
      if (response && response.code == "200") {
        let data = response.data;
        this.form.pictureName = data.fileName;
        this.form.pictureUrl = process.env.VUE_APP_API_BASE_URL + data.url;
        this.uploading = false;
      } else if(response && response.code !== '200') {
        this.removeFile()
        this.$message.error(response.message);
      } else {
        this.uploading = true;
      }
      if (this.isLt2M) {
        this.fileList = info.fileList;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.report {
  position: relative;
  top: 8px;
  background: #fff;
  padding: 1.85rem 3.99% 0 3.99%;
  .iconfont {
    font-size: 1.6rem;
    span {
      font-size: 1.4rem;
      margin-left: 5px;
    }
  }
  .form-item {
    margin-bottom: 1.8rem !important;
    .ant-input {
      margin-top: 1.5rem;
    }
  }

  .form-select {
    // width: 50%;
    text-align: right;
    border: 0;
    outline: 0;
    background: none;
  }
  .icon-color {
    color: #909399 !important;
    font-size: 1.8rem;
  }
  .upload-img {
    margin-top: 1.5rem;
    .ant-btn-lg,
    .ant-upload.ant-upload-select-picture-card {
      width: 6rem !important;
      height: 6rem !important;
    }
  }
  .right-tip {
    color: #bdbdbd;
    font-size: 12px;
    position: absolute;
    right: 8px;
    bottom: 8px;
  }
}
.type-select-box {
  display: flex;
  flex-direction: column;
}
select {
  // direction: rtl; // 文本方向右到左
  text-align-last:right;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  text-align: right;
  /*在选择框的最右侧中间显示小箭头图片*/
  /* background: url("http://ourjs.github.io/static/2015/arrow.png") no-repeat scroll right center transparent;*/
  background-color: transparent;

  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 20px;
  
}
.h {
  width: 0px;
  height: 0px;
  border: 50px solid transparent;
  border-top-color: red;
}
select option {
  text-align: right;
}
</style>
