<template>
  <div class="top-back flex-center fz18">
    <a-icon type="left" @click="goBack" />
    <span>{{ title }}</span>
  </div>
</template>
<script>
import { Icon } from "ant-design-vue";
export default {
  components: {
    AIcon: Icon,
  },
  props: {
    title: String,
    path: {
      type: String,
      default: "-1",
    },
    isEdit: {
      type: String,
      default: "",
    }
  },
  methods: {
    goBack() {
      if (this.path == "-1" || this.isEdit) {
        this.$router.go("-1");
      } else if(this.path !== '-1'){
         this.$router.push(this.path);
      }else  {
        this.$router.push("/home");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top-back {
  padding: 0 3.2%;
  height: 44px;
  margin: 0 auto;
  width: 100%;
  max-width: 750px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #f39800;
  color: #fff;
  span {
    width: 100%;
    text-align: center;
    display: block;
  }
}
</style>